@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  //Colours
  --colour-primary: #673ab7;
  --colour-secondary: #ffffff;
  --colour-tertiary: #000000;
  --colour-good: #4caf50;
  --colour-neutral: #348aec;
  --colour-bad: #f44336;

  --text-colour-primary: #fff;
  --text-colour-secondary: #348aec;
  --text-colour-tertiary: #000000;

  //Global Element Modification
  html, body, p, h1, h2, h3 {
    margin: 0;
    padding: 0;
  }

  h1 {
    padding-bottom: 12px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    line-height: 1;
  }

  .text-bold {
    font-weight: 500;
  }

  html, body {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }

  //Global classes must use the Project Sphynx 'ps-' prefix
  .ps-button--primary {
    background-color: var(--colour-primary) !important;
    color: var(--text-colour-primary) !important;
  }

  .ps-button--good {
    background-color: var(--colour-good) !important;
    color: var(--text-colour-primary) !important;
  }

  .ps-button--bad {
    background-color: var(--colour-bad) !important;
    color: var(--text-colour-primary) !important;
  }

  .ps-button--neutral {
    background-color: var(--colour-neutral) !important;
    color: var(--text-colour-primary) !important;
  }

  .ps-button--xs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
  }

  .ps-button--xxs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
  }
}

